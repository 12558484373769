import React from "react";
import Div100vh from "react-div-100vh";
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import HomeButton from "../components/HomeButton";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Menu, { BeaverQuestion, TableOfContents } from "../components/Menu";
import { FancyItalics } from "../components/HeaderBar";
import Footer from "../components/Footer";
import ExpandedTOC from "../components/ExpandedTOC";
import { Helmet } from "react-helmet";

// styles
const styles = {
  pageStyles: css`
    color: var(--gold);
    background-color: var(--blue);
    padding: 0;
    font-family: "AvantGardeCondensed", georgia, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    padding: 10px;

    a,
    a:link,
    a:active,
    a.visited {
      color: black;
      //text-decoration: none;
      //text-transform: uppercase;
    }
  `,
  siteMapLayout: css`
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: 200px 1fr;

    a,
    a:link,
    a:active,
    a.visited {
      color: #9c7da5;
      text-decoration: none;
      text-transform: uppercase;
    }

    h2 {
      font-size: 5.9vw;
      text-align: center;
      padding: 20px;
    }

    li > ul {
      padding-left: 20px;
    }
  `,
  siteMap: css`
    padding: 0 20px 0 20px;
  `,
  gridStyle: css`
    height: 100vh;
    width: 100vw;
    list-style: none;
    padding: 0;
    margin: 0;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-template-rows: 1fr 1fr;

    a,
    a:link,
    a:active,
    a.visited {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
    }

    h2 {
      font-size: 5.9vw;
      text-align: center;
      padding: 20px;
    }
  `,
  gridItems: ({ backgroundColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundColor};
  `,
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const headingAccentStyles = {
  color: "#663399",
};
const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
};

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
};

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
};

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
};

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
};

const BelowTheFold = styled.div`
  max-width: var(--defaultColumnMaxWidth);
  margin: 2rem auto 0 auto;

  .treaty-guide-header {
    text-align: center;
    margin-top: 100px;
  }

  .toc div {
    color: black !important;
  }

  .toc li {
    list-style-type: none;
  }

  .toc ul li a {
    text-decoration: none;
    color: var(--blue);
  }

  @media screen and (max-width: 867px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
};

// data
const theGrid = [
  {
    text: "The Book",
    url: "/book",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#515c9a",
  },
  {
    text: "About",
    url: "/about",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#7579ac",
  },
  {
    text: "Activities",
    url: "/activities",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#9b9cc2",
  },
  {
    text: "Indigenous Presence",
    url: "/indigenous-presence",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#c8c8dd",
  },
];

function DownArrow() {
  return (
    <StaticImage
      src="../images/arrow icon.png"
      alt="Cover of Treaty Guide"
      layout="fixed"
      width={50}
      css={css`
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        transform: rotate(90deg);
        left: 50%;
        margin-left: -25px;
      `}
    />
  );
}

// markup
const IndexPage = ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Treaties for Torontonians</title>
      </Helmet>
      <main css={styles.pageStyles}>
        <h1
          css={css`
            //font-size: 158.889px;
            //line-height: 136px;
            font-size: 12vw;
            line-height: 12vw;
            text-align: center;
            margin: 0;
            color: var(--gold);
            font-family: var(--defaultHeaderFontBold);
            max-width: none;
            text-transform: none;
            @media (min-width: 1200px) {
              font-size: 158.889px;
              line-height: 136px;
            }
          `}
        >
          A<br />
          <FancyItalics
            css={css`
              //font-size: 158.889px;
              //line-height: 136px;
              font-size: 12vw;
              line-height: 12vw;
              @media (max-width: 600px) {
                font-size: 12vw;
                line-height: 12vw;
              }
              @media (min-width: 1200px) {
                font-size: 158.889px;
                line-height: 136px;
              }
            `}
          >
            Treaty
          </FancyItalics>
          <br />
          Guide for
          <br />
          <FancyItalics
            css={css`
              //font-size: 158.889px;
              //line-height: 136px;
              font-size: 12vw;
              line-height: 12vw;
              @media (max-width: 600px) {
                font-size: 12vw;
                line-height: 12vw;
              }
              @media (min-width: 1200px) {
                font-size: 158.889px;
                line-height: 136px;
              }
            `}
          >
            Torontonians
          </FancyItalics>
        </h1>
        <h2
          css={css`
            text-transform: uppercase;
            font-style: italic;
            //font-size: 36px;
            //line-height: 36px;
            font-size: 3vw;
            line-height: 3vw;
            font-family: var(--defaultBodyFont);
            color: var(--gold);
            @media (min-width: 1200px) {
              font-size: 36px;
              line-height: 36px;
            }
          `}
        >
          Talking Treaties Collective
        </h2>
        <BeaverQuestion />
        {/*<DownArrow />*/}
      </main>
      <BelowTheFold style={{ marginBottom: "75px" }}>
        <h2 style={{ textAlign: "center", maxWidth: "500px" }}>
          WHAT DOES IT MEAN TO BE A TREATY PERSON IN TORONTO?
        </h2>
        <p>
          <i>A Treaty Guide for Torontonians</i> is an artful examination of the
          complex intercultural roots of treaty relationships in the place we
          now call Toronto. From the Two Row Wampum and Dish with One Spoon to
          the Treaty of Niagara and the Toronto Purchase, we trace the history
          of treaty making between Indigenous nations, and between Indigenous
          nations and the Crown. Part of Jumblies Theatre + Arts’ multiyear
          Talking Treaties project, <i>A Treaty Guide</i> inspires an active
          approach to treaty awareness through embodied learning tools.
          Land-based activities, theatrical exercises, and drawing and writing
          prompts help readers find their own relationship to this history, and
          to take up their treaty responsibilities in the present.
        </p>
        <ExpandedTOC />
      </BelowTheFold>
      <Menu />
      <Footer siteSummary={false} />
    </>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { status: { eq: "active" } } }
    ) {
      nodes {
        id
        frontmatter {
          slug
          status
          stage
        }
        internal {
          contentFilePath
        }
      }
    }
  }
`;

export default IndexPage;
